<template>
    <vue-easymde ref="easymde" :name="name" :value="value" :configs="config" :sanitize="true" :highlight="true"/>
</template>

<script>
    import VueEasymde from 'vue-easymde';
    import EasyMDE from 'easymde';

    export default {
        name: 'MarkdownEditor',
        components: {
            VueEasymde
        },
        props: {
            name: String,
            value: String,
            config: {
                type: Object,
                default() {
                    let buttons = [
                        {
                            name: 'bold',
                            action: EasyMDE.toggleBold,
                            className: 'fas fa-bold',
                            title: 'Félkövér'
                        },
                        {
                            name: 'italic',
                            action: EasyMDE.toggleItalic,
                            className: 'fas fa-italic',
                            title: 'Dőlt'
                        },
                        {
                            name: 'strikethrough',
                            action: EasyMDE.toggleStrikethrough,
                            className: 'fas fa-strikethrough',
                            title: 'Áthúzott'
                        },
                        {
                            name: 'heading',
                            action: EasyMDE.toggleHeadingSmaller,
                            className: 'fas fa-heading',
                            title: 'Címsor'
                        },
                        {
                            name: 'heading-smaller',
                            action: EasyMDE.toggleHeadingSmaller,
                            className: 'fas fa-heading fa-header-x fa-header-smaller',
                            title: 'Kisebb címsor'
                        },
                        {
                            name: 'heading-bigger',
                            action: EasyMDE.toggleHeadingBigger,
                            className: 'fas fa-heading fa-header-x fa-header-bigger',
                            title: 'Nagyobb címsor'
                        },
                        '|',
                        {
                            name: 'code',
                            action: EasyMDE.toggleCodeBlock,
                            className: 'fas fa-code',
                            title: 'Kód'
                        },
                        {
                            name: 'quote',
                            action: EasyMDE.toggleBlockquote,
                            className: 'fas fa-quote-left',
                            title: 'Idézet'
                        },
                        {
                            name: 'unordered-list',
                            action: EasyMDE.toggleUnorderedList,
                            className: 'fas fa-list-ul',
                            title: 'Lista'
                        },
                        {
                            name: 'ordered-list',
                            action: EasyMDE.toggleOrderedList,
                            className: 'fas fa-list-ol',
                            title: 'Számozott lista'
                        },
                        {
                            name: 'clean-block',
                            action: EasyMDE.cleanBlock,
                            className: 'fas fa-eraser fa-clean-block',
                            title: 'Formázás törlése'
                        },
                        '|',
                        {
                            name: 'link',
                            action: EasyMDE.drawLink,
                            className: 'fas fa-link',
                            title: 'Hivatkozás beszúrása'
                        },
                        {
                            name: 'image',
                            action: EasyMDE.drawImage,
                            className: 'fas fa-image',
                            title: 'Kép beszúrása'
                        },
                        {
                            name: 'table',
                            action: EasyMDE.drawTable,
                            className: 'fas fa-table',
                            title: 'Táblázat beszúrása'
                        },
                        {
                            name: 'horizontal-rule',
                            action: EasyMDE.drawHorizontalRule,
                            className: 'fas fa-minus',
                            title: 'Vízszintes vonal beszúrása'
                        },
                        '|',
                        {
                            name: 'preview',
                            action: EasyMDE.togglePreview,
                            className: 'fas fa-eye no-disable',
                            title: 'Előnézet'
                        },
                        {
                            name: 'side-by-side',
                            action: EasyMDE.toggleSideBySide,
                            className: 'fas fa-columns no-disable no-mobile',
                            title: 'Párhuzamos előnézet'
                        },
                        {
                            name: 'fullscreen',
                            action: EasyMDE.toggleFullScreen,
                            className: 'fas fa-arrows-alt no-disable no-mobile',
                            title: 'Teljes képernyős nézet'
                        },
                        '|',
                        {
                            name: 'guide',
                            action: 'https://simplemde.com/markdown-guide',
                            className: 'fas fa-question-circle',
                            title: 'Markdown súgó'
                        },
                        '|',
                        {
                            name: 'undo',
                            action: EasyMDE.undo,
                            className: 'fas fa-undo no-disable',
                            title: 'Visszavonás'
                        },
                        {
                            name: 'redo',
                            action: EasyMDE.redo,
                            className: 'fas fa-redo no-disable',
                            title: 'Mégis'
                        },
                    ];
                    buttons.forEach(function (btn) {
                        if (btn instanceof Object) {
                            btn.className += ' text-dark';
                        }
                    });
                    return {
                        inputStyle: 'contenteditable',
                        nativeSpellcheck: true,
                        spellChecker: false, // TODO: magyarul ellenőrizze
                        /*customDict: {
                            dic: 'https://raw.githubusercontent.com/titoBouzout/Dictionaries/master/Hungarian.dic',
                            aff: 'https://raw.githubusercontent.com/titoBouzout/Dictionaries/master/Hungarian.aff',
                            customWords: [''],
                            ignoreCodeBlocks: true,
                        },*/
                        autoDownloadFontAwesome: false,
                        toolbar: buttons,
                        shortcuts: {
                            drawTable: 'Cmd-Alt-T',
                            toggleStrikethrough: 'Cmd-Alt-S',
                            toggleBlockquote: 'Cmd-Q',
                            undo: 'Cmd-Z',
                            redo: 'Cmd-Y',
                        },
                        promptURLs: true,
                        promptTexts: {
                            image: 'Kép URL beillesztése:',
                            link: 'Hivatkozás URL beillesztése:',
                        },
                        insertTexts: {
                            table: ['',
                                '\n\n| Oszlop 1 | Oszlop 2 | Oszlop 3 |\n' +
                                '| -------- | -------- | -------- |\n' +
                                '| Cella 1  | Cella 2  | Cella 3  |\n\n'
                            ],
                        },
                        status: ['lines', 'words', 'cursor'],
                    };
                },
            }
        }
    };
</script>

<style>
    @import '~easymde/dist/easymde.min.css';

    .editor-statusbar .lines:before,
    .editor-statusbar .words:before {
        content: '';
    }
    .editor-statusbar .lines:after {
        content: ' sor';
    }
    .editor-statusbar .words:after {
        content: ' szó';
    }
</style>
